import FormStore from "../../../utils/stores/FormStore"

const initState = {
    from: null,
    to: null,
    Categories: [],
    Territory: "",
    Locality: "",
    SenderId: "",
    Sources: [],
}

const validateData = {
    territory: {required: true}
}

class CreatedSummaryFilterStore extends FormStore {
    constructor() {
        super({initState, validateData})
        this.dateNames = [["from", "to"]]
    }

    clear = () => {
        this.setData(initState)
        this.clearSelectedFilters()
    }
}

export default CreatedSummaryFilterStore