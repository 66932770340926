import React, {useContext, useMemo} from "react"
import {useIntl} from "react-intl"
import moment from "moment/moment"
import MenuItem from "@mui/material/MenuItem"
import {CreatedSummaryContext} from "../context/withCreatedSummary"
import {observer} from "mobx-react"
import NewFilters from "../../../components/NewFilters/NewFilters"
import CreatedSummeryFilterChip from "./CreatedSummeryFilterChip"
import downloadWhite from "../../../assets/images/common/downloadWhite.svg"
import downloadGray from "../../../assets/images/common/downloadGray.svg"

const CreatedFilters = () => {
    const intl = useIntl()
    const {
        territoryStore,
        categoriesStore,
        sendersStore,
        sourcesStore,
        filterStore,
        getStatistic,
        collection,
        loadFile
    } = useContext(CreatedSummaryContext)
    const filtersData = useMemo(() => {
        return [
            [
                {
                    type: "select",
                    propName: "Territory",
                    name: intl.formatMessage({id: "Регион"}),
                    collection: territoryStore.collection
                },
                [
                    {
                        type: "date",
                        propName: "from",
                        name: intl.formatMessage({id: "Дата начала периода"}),
                        minDate: moment(window.SAT.config.minDateOperationalSummary).utc().format("YYYY-MM-DD HH:mm"),
                        maxDate: moment().utc(true).format("YYYY-MM-DD HH:mm")
                    },
                    {
                        type: "date",
                        propName: "to",
                        name: intl.formatMessage({id: "Дата окончания периода"}),
                        minDate: moment(window.SAT.config.minDateOperationalSummary).utc().format("YYYY-MM-DD HH:mm"),
                        maxDate: moment().utc(true).format("YYYY-MM-DD HH:mm")
                    }
                ],
                {
                    type: "select",
                    multiple: true,
                    propName: "Categories",
                    collection: categoriesStore.collection.map((category) => {
                        return {value: category.id, key: category.name}
                    }),
                    setMenuItem: (el) => {
                        return <MenuItem value={el.value}>{el.key}</MenuItem>
                    },
                    name: intl.formatMessage({id: "Льготные категории"})
                },
                {
                    type: "select",
                    propName: "SenderId",
                    collection: sendersStore.allSenders,
                    name: intl.formatMessage({id: "Источник"}),
                    setMenuItem: (el) => {
                        return (
                            <MenuItem key={el.id} value={el.id}>
                                {el.name}
                            </MenuItem>
                        )
                    }
                },
                {
                    type: "select",
                    multiple: true,
                    name: intl.formatMessage({id: "Способ добавления"}),
                    propName: "Sources",
                    collection: sourcesStore.sources,
                    setMenuItem: (el) => {
                        return (
                            <MenuItem key={el.id + el.name} value={el.id}>
                                {el.name}
                            </MenuItem>
                        )
                    }
                }
            ]
        ]
    }, [categoriesStore.collection, intl, sendersStore.allSenders, sourcesStore.sources, territoryStore.collection])

    const handleDeleteChip = (propName, multiselectValue) => {
        filterStore.deleteFilter(propName, multiselectValue)
        getStatistic()
    }

    const setChip = (params, withAction) => (
        <CreatedSummeryFilterChip {...params} onClick={withAction && handleDeleteChip} />
    )

    return (
        <NewFilters
            filtersStore={filterStore}
            onSearch={getStatistic}
            filtersData={filtersData}
            setChip={setChip}
            actionButtonProps={{
                name: intl.formatMessage({id: "Сохранить в файл"}),
                disabled: !collection.length,
                onClick: loadFile,
                icon: collection.length ? downloadWhite : downloadGray
            }}
            title={intl.formatMessage({id: "Результаты поиска"})}
        />
    )
}

export default observer(CreatedFilters)
