import React from "react"
import {observer} from "mobx-react"
import {withCreatedStore} from "./context/withCreatedSummary"
import Box from "@mui/material/Box"
import CreatedFilters from "./components/CreatedFilters"
import CreatedTable from "./components/CreatedTable"

const CreatedSummary = () => {
    return (
        <Box width={1}>
            <Box mb={2}>
                <CreatedFilters/>
            </Box>
            <CreatedTable/>
        </Box>
    )
}

export default withCreatedStore(observer(CreatedSummary))
