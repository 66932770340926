import {getComp} from "../../../utils/utils/DI"
import {makeAutoObservable} from "mobx"
import {getCreatedSummary} from "../../../services/ApiService"
import CreatedSummaryFilterStore from "./CreatedSummaryFilterStore"
import {gState} from "../../../utils/controllers/GlobalStateController"
import moment from "moment"

class CreatedSummaryStore {
    constructor() {
        this.filterStore = new CreatedSummaryFilterStore()
        this.territoryStore = getComp("TerritoryStore")
        this.categoriesStore = getComp("CategoriesStore")
        this.sendersStore = getComp("SendersStore")
        this.sourcesStore = getComp("SourcesStore")
        this.collection = []
        makeAutoObservable(this)
    }

    loadFile = () => {
        const intl = gState["intl"]
        const fields = [[intl.formatMessage({id: "Регион / МО или ГО"}), intl.formatMessage({id: "Всего создано по территории"})]]
        const sortedKeys = ["unitedTerritoryName", "totalInstalled"]

        this.collection.forEach((row) => {
            fields.push(sortedKeys.map(key => {
                if (row[key] !== undefined) {
                    return row[key]
                }
                return "-"
            }))
        })

        const blobData = fields.map(el => el.join(window.SAT.config.csvParams.divider)).join("\n")
        const data = new TextEncoder("utf-16be").encode(blobData)
        const blob = new File(["\uFEFF", data], intl.formatMessage({id: "Заявки"}),
            {type: `text/csv;charset=utf-8`})
        window.location = window.URL.createObjectURL(blob)
    }

    _groupByName = (statisticResult) => {
        return statisticResult.reduce((row, item) => {
            const {name, total} = item
            row[name] = row[name] ? row[name] + total : total
            return row
        }, {})
    }

    getStatistic = () => {
        const requestParam = {}
        for (const key in this.filterStore.data) {
            if (this.filterStore.data[key] !== "" && this.filterStore.data[key] !== null && this.filterStore.data[key] !== "Invalid date") {
                requestParam[key] = this.filterStore.data[key]
                if (key === "from" || key === "to") {
                    requestParam[key] = moment(this.filterStore.data[key]).local(true).format()
                }
            }
        }
        return getCreatedSummary(requestParam).then(res => {
            this.filterStore.setSelectedFilters()
            const groupedByName = this._groupByName(res)
            const arrayFromGrouped = Object.entries(groupedByName).map(([key, value]) => {
                return {unitedTerritoryName: key, totalInstalled: value}
            })

            const totalResultRow = {
                totalInstalled: 0,
                unitedTerritoryName: gState["intl"].formatMessage({id: "ИТОГО (по всем записям)"}),
                totalLast: true
            }

            totalResultRow.totalInstalled = arrayFromGrouped.reduce((acc, item) => {
                acc = acc + item.totalInstalled
                return acc
            }, 0)

            arrayFromGrouped.push(totalResultRow)
            this.collection = arrayFromGrouped
        })
    }

}

export default CreatedSummaryStore