import {observer} from "mobx-react"
import React, {useCallback} from "react"
import {useIntl} from "react-intl"
import {getComp} from "../../../utils/utils/DI"
import Chip from "../../../components/Chips/Chip"

function CreatedSummeryFilterChip({propName, value, onClick}) {
    const intl = useIntl()
    const {getCategoryName} = getComp("CategoriesStore")
    const {getSenderName} = getComp("SendersStore")
    const {getSourceName} = getComp("SourcesStore")
    const setChipAction = useCallback(() => {

        return onClick ? () => onClick(propName, value) : null
    }, [value])

    const setValue = useCallback(() => {
        switch (propName) {
            case ("from"): {
                return intl.formatMessage({id: "Период"}) + " " + value
            }
            case ("Categories"): {
                return getCategoryName(value)
            }
            case ("Sources"): {
                return getSourceName(value)
            }
            case ("SenderId"): {
                return getSenderName(value)
            }
            default: {
                return value
            }
        }
    }, [value])

    return <Chip action={setChipAction()} value={setValue()}/>

}

export default observer(CreatedSummeryFilterChip)