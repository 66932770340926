import React, { useContext, useMemo } from 'react'
import { useIntl } from 'react-intl'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import CustomTable from '../../../utils/components/CustomTable/CustomTable'
import { observer } from 'mobx-react'
import { CreatedSummaryContext } from '../context/withCreatedSummary'

const CreatedTable = () => {
    const { collection } = useContext(CreatedSummaryContext)
    const intl = useIntl()
    const columns = useMemo(() => {
        return [
            {
                propName: 'unitedTerritoryName',
                headerElement: (
                    <Box key={'HeaderNameColumn'} className={'fx-center fx-nowrap'} sx={{ minWidth: '120px' }}>
                        <Typography
                            variant={'body2'}
                            sx={{
                                width: '100%',
                                textAlign: 'left',
                                height: 'max-content',
                                padding: '4px 0',
                                cursor: 'default',
                            }}
                        >
                            {intl.formatMessage({ id: 'Регион / МО или ГО' })}
                        </Typography>
                    </Box>
                ),
                renderCell: (params) => {
                    return (
                        <Typography
                            className={'aln-center'}
                            key={params.unitedTerritoryName + 'rowName'}
                            variant={'body1'}
                            sx={{ width: '100%', height: '100%', fontWeight: '500', textAlign: 'left' }}
                        >
                            {params.unitedTerritoryName}
                        </Typography>
                    )
                },
                headerStart: true,
            },
            {
                propName: 'totalInstalled',
                headerElement: (
                    <Box key={'HeaderNameColumn'} className={'fx-center fx-nowrap'} sx={{ minWidth: '120px' }}>
                        <Typography
                            variant={'body2'}
                            sx={{
                                width: '100%',
                                textAlign: 'left',
                                height: 'max-content',
                                padding: '4px 0',
                                cursor: 'default',
                            }}
                        >
                            {intl.formatMessage({ id: 'Всего создано по территории' })}
                        </Typography>
                    </Box>
                ),
                renderCell: (params) => {
                    return (
                        <Typography
                            key={params.totalInstalled + params.unitedTerritoryName + 'rowTotal'}
                            variant={'body1'}
                            className={'fx-center'}
                            sx={{ width: '100%', height: '100%', textAlign: 'center' }}
                        >
                            {params.totalInstalled}
                        </Typography>
                    )
                },
            },
        ]
    }, [intl])

    return (
        <CustomTable
            containerStyle={{ maxHeight: 'calc(62vh)' }}
            showPagination={false}
            columns={columns}
            rows={collection}
            total={collection.length}
            noContentText={
                <Typography sx={{ fontWeight: '500', color: '#A9A9A9', fontSize: '13px' }}>
                    {intl.formatMessage({ id: 'выберите параметры для отображения данных' })}
                </Typography>
            }
        />
    )
}

export default observer(CreatedTable)
