import React, {useState} from "react"
import {observer} from "mobx-react"
import CreatedSummaryStore from "src/views/CreatedSummary/stores/CreatedSummaryStore"

export const CreatedSummaryContext = React.createContext({})

const WithCreatedStore = observer(({children}) => {
    const [createdStore] = useState(new CreatedSummaryStore())

    return (
        <CreatedSummaryContext.Provider value={createdStore}>
            {children}
        </CreatedSummaryContext.Provider>
    )
})

export function withCreatedStore(Component) {
    return function withCreatedStoreComponent(props) {
        return (
            <WithCreatedStore>
                <Component {...props}/>
            </WithCreatedStore>
        )
    }
}